import React from 'react'

const Footer = () => {
  return (
    <div className='mt-10'>
      <div className='text-center p-4 bg-gray-500 text-white text-sm font-light'>Copyright © 2024 INNOVAPP LLC All Right Reserve.</div>
    </div>
  )
}

export default Footer
