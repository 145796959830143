import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Home from './pages/Home';
import Statement from './pages/Statement';
import TermsOfService from './pages/TermsOfService';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Support from './pages/Support';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
    <Routes>  
      <Route path="/" element={<Home/>} />
      <Route path="/statement" element={<Statement/>} />
      <Route path="/terms-of-service" element={<TermsOfService/>} />
      <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
      <Route path="/support" element={<Support/>} />
    </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
