import React from 'react'
import Navigation from '../component/Navigation'

const Support = () => {
  return (
    <div>
        <Navigation/>
        <div className=' max-w-[1280px] m-auto p-4 mt-20'>
        <div className='bg-blue-500 py-16 text-center rounded-lg'>
        <h1 className='text-5xl text-white uppercase'>support</h1>
        </div>
      </div>
      <div className='h-[80rem]'>
      <iframe
        src="https://docs.google.com/forms/d/14LNEwEqnQnXgj2REAgNo3CAWQuFSED4tMlo_kae4Cn8/viewform?embedded=true"
        width="100%"
        height="100%"
        title="Google Form"
      >
        Loading…
      </iframe>
    </div>
    </div>
  )
}

export default Support
