import React from 'react'
import Navigation from '../component/Navigation'
import Footer from '../component/Footer'

const TermsOfService = () => {
  return (
    <div>
        <Navigation/>
        <div className=' max-w-[1280px] m-auto p-4 mt-20'>
        <div className='bg-blue-500 py-16 text-center rounded-lg'>
        <h1 className='text-5xl text-white uppercase'>Terms of Service</h1>
        </div>

        <div className='mt-10'>
              <p>Before using Our Service, kindly review these terms and conditions completely.</p>
      <h2 className='text-3xl my-3'>Interpretation and Definitions</h2>
      <h3 className='text-xl my-4'>Interpretation</h3>
      <p className='my-1'>The capitalized terms within this document carry specific meanings, and it is essential to maintain consistency in using both their singular and plural forms.</p>
      <h3 className='text-xl my-4'>Definitions</h3>
      <p className='my-1'>For the purposes of these Terms and Conditions:</p>
      <ul className='list-disc'>
          <li>Application means the software program provided by the Company downloaded by You on any electronic device, named Healthy Life.</li>
          <li>Application Store means the digital distribution service operated and developed by Apple Inc.</li>
          <li>Affiliate means an entity that controls, is controlled by, or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest, or other securities entitled to vote for election of directors or other managing authority.</li>
          <li>Company (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to INNOVAPP LLC.</li>
          <li>Device means any device that can access the Service such as a cellphone or a digital tablet.</li>
          <li>Free Trial refers to a limited period of time that may be free when purchasing a Subscription.</li>
          <li>In-app Purchase refers to the purchase of a product, item, service, or Subscription made through the Application and subject to these Terms and Conditions and/or the Application Store's own terms and conditions.</li>
          <li>Service refers to the Application.</li>
          <li>Subscriptions refer to the services or access to the Service offered on a subscription basis by the Company to You.</li>
          <li>Terms and Conditions (also referred as "Terms") mean these Terms and Conditions that form the entire agreement between You and the Company regarding the use of the Service.</li>
          <li>Third-party Social Media Service means any services or content (including data, information, products, or services) provided by a third-party that may be displayed, included, or made available by the Service.</li>
          <li>You means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
      </ul>

      <h3 className='text-xl my-4'>Acknowledgment</h3>
      <p className='my-1'>Your agreement with the Company regarding the use of the Service is detailed in these Terms and Conditions. Please carefully review and adhere to these terms to access and use the Service. By using the Service, you acknowledge and accept these terms, and it is imperative for all users to comply with them. If there are any aspects with which you disagree, please refrain from using the Service.</p>
      <p className='my-1'>Additionally, please confirm your age as the Company does not permit users under the age of eighteen to access the Service.</p>
      <p className='my-1'>Furthermore, utilizing the Service implies adherence to the Company's Privacy Policy, which outlines the collection, use, and disclosure of personal data. Before utilizing the Service, take the time to read the Privacy Policy.</p>

      <h3 className='text-xl my-4'>Subscriptions</h3>
      <p className='my-1'>Subscription period</p>
      <p className='my-1'>Access to the Service or specific features requires a paid subscription. Depending on the Subscription plan you choose, you will be billed in advance on a recurring basis, whether it be daily, weekly, monthly, or yearly.</p>
      <p className='my-1'>Unless either you or the Company cancels the subscription at the end of each term, it will automatically renew.</p>

      <h3 className='text-xl my-4'>Subscription cancellations</h3>
      <p className='my-1'>To cancel the Subscription renewal, you can either go to your Account settings or get in touch with the Company. Please note that no refunds will be issued for payments made during the current Subscription term. Access to the Service will be provided until the current Subscription term concludes.</p>
      <p className='my-1'>If you acquired the Subscription through an in-app purchase, you have the option to cancel the renewal directly through the Application Store.</p>

      <h3 className='text-xl my-4'>Billing</h3>
      <p className='my-1'>In the event of automated billing failure, the Company will issue an electronic invoice that necessitates manual payment by a specified deadline, covering the entire billing period. For In-app Purchases, billing is handled by the Application Store in accordance with its terms and conditions.</p>

      <h3 className='text-xl my-4'>Fee Changes</h3>
      <p className='my-1'>The Company reserves the right to modify Subscription costs at its discretion, and such changes will be implemented at the conclusion of the ongoing Subscription period. You will receive sufficient notice regarding any alterations to pricing, affording you the opportunity to cancel your Subscription before the changes become effective.</p>
      <p className='my-1'>Should you choose to continue using the Service after a revision in charges, it will be considered an acceptance of the new Subscription fee.</p>

      <h3 className='text-xl my-4'>Refunds</h3>
      <p className='my-1'>In general, paid subscription fees are non-refundable unless required by law. The Company retains the right to assess refund requests for Subscriptions on a case-by-case basis. If you seek a refund, please reach out to the Application Store, as their return policy applies when the Subscription was acquired through an in-app purchase.</p>

      <h3 className='text-xl my-4'>Free Trial</h3>
      <p className='my-1'>The Company may, at its sole discretion, offer a Subscription with a Free Trial for a limited period. To sign up for the Free Trial, you may need to provide billing information. If you input your billing details, you will not be charged until the Free Trial concludes. In the absence of cancellation, you will be billed the applicable Subscription costs on the last day of the Free Trial.</p>
      <p className='my-1'>The Company retains the right to modify or terminate the Free Trial offer at any time and without prior notice.</p>

      <h3 className='text-xl my-4'>In-app Purchases</h3>
      <p className='my-1'>In-app Purchases may be accessible through the Application, enabling you to acquire items, services, or subscriptions. Information on how to manage these purchases on your device can be found in the Application Store's terms or in the Help settings on your device.</p>
      <p className='my-1'>These in-app purchases are limited to use within the application and are non-refundable after download. They hold no cash value and cannot be transferred.</p>
      <p className='my-1'>If an In-app Purchase fails to download or is defective, we will promptly assess the situation and determine whether a replacement or fix can be provided at no additional cost. If such actions are not feasible, the Application Store is authorized to reimburse the cost of the In-app Purchase. For refund claims, please reach out to the Application Store directly, as billing processes are governed by its rules.</p>
      <p className='my-1'>Your acknowledgment and agreement with these procedures, including billing matters, are recognized, and any related concerns should be directed to the Application Store. For any payment-related issues concerning In-app Purchases, kindly contact the Application Store directly.</p>

      <h3 className='text-xl my-4'>Intellectual Property</h3>
      <p className='my-1'>The Service, along with its distinctive content, features, and functionality (excluding User-provided material), is and will remain the exclusive property of the Company and its licensors.</p>
      <p className='my-1'>It is protected by copyright, trademark, and other national and international laws.</p>
      <p className='my-1'>The utilization of our trademarks and trade dress requires prior written approval from the Company and is prohibited in connection with any product or service unless such consent is obtained.</p>

      <h3 className='text-xl my-4'>Links to Other Websites</h3>
      <p className='my-1'>Our Service may include links to third-party websites or services that the Company does not own or control.</p>
      <p className='my-1'>The Company lacks authority over the content, privacy policies, or practices of any third-party websites or services and assumes no responsibility for them. You acknowledge and agree that the Company is not directly or indirectly liable for any damage or loss purportedly caused by or in connection with the use of or reliance on any such content, products, or services accessible on or through any such websites or services.</p>
      <p className='my-1'>It is highly recommended that you review the terms and conditions, as well as the privacy policies, of any third-party websites or services you access.</p>

      <h3 className='text-xl my-4'>Termination</h3>
      <p className='my-1'>We reserve the right to promptly cancel or suspend your access to the Service for any reason, including if you violate these Terms and Conditions, without prior notice or liability. Upon termination of your subscription, your right to use the Service will cease immediately.</p>

      <h3 className='text-xl my-4'>Limitation of Liability</h3>
      <p className='my-1'>Despite any damages you might incur, the entire liability of the Company and any of its suppliers under these terms, and your exclusive remedy for all the foregoing, shall be limited to the amount actually paid by you through the service or 100 USD if you haven’t purchased anything through the service.</p>
      <p className='my-1'>To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, loss of data or other information, for business interruption, for personal injury, loss of privacy arising out of or in any way related to the use of or inability to use the service, third-party software and/or third-party hardware used with the service, or otherwise in connection with any provision of this terms), even if the company or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.</p>
      <p className='my-1'>Some states do not allow the exclusion of implied warranties or limitation of liability for incidental or consequential damages, which means that some of the above limitations may not apply. In these states, each party’s liability will be limited to the greatest extent permitted by law.</p>

      <h3 className='text-xl my-4'>"AS IS" and "AS AVAILABLE" Disclaimer</h3>
      <p>
      The Service is provided to you "AS IS" and "AS AVAILABLE," with all errors and imperfections, and without any warranties of any kind. To the fullest extent permitted by applicable law, the Company, its Affiliates, and licensors disclaim all warranties, whether express, implied, statutory, or otherwise. This includes implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement, as well as those arising from course of dealing, course of performance, usage, or trade practice.
      <br />
      <br />
      The Company makes no warranty or guarantee that the Service will meet your requirements, achieve desired outcomes, be compatible with other software, operate without interruption, meet performance or reliability standards, or be error-free. Additionally, no assurance or warranty is given regarding the operation or availability of the Service, the accuracy, reliability, or currency of information or materials, or the absence of viruses or harmful components.
      <br />
      <br />
      Due to certain jurisdictions not allowing certain warranty exclusions or limitations on consumer rights, the aforementioned exclusions and limitations may not apply in full. They shall be applied to the maximum extent permissible under the applicable law in such circumstances.
      </p>
      <h3 className='text-xl my-4'>"Governing Law</h3>
      <p className='my-1'>These Terms and your use of the Service shall be governed by the laws of the country, without regard to its conflict of laws rules. Additional local, state, national, and international laws may also apply to your use of the Application.</p>
      <h3 className='text-xl my-4'>"Disputes Resolution</h3>
      <p className='my-1'>If you encounter any issues or disputes regarding the Service, you agree to attempt an informal resolution by contacting the Company first.</p>
      <h3 className='text-xl my-4'>For European Union (EU) Users</h3>
      <p className='my-1'>If you are a consumer in the European Union, you will have rights guaranteed by the mandatory provisions of the legislation in your country of residence.</p>
      <h3 className='text-xl my-4'>United States Federal Government End Use Provisions</h3>
      <p className='my-1'>If you are a U.S. federal government end user, our Service is considered a "Commercial Item" as defined in 48 C.F.R. §2.101.</p>
      <h3 className='text-xl my-4'>United States Legal Compliance</h3>
      <p className='my-1'>You confirm and warrant that (i) you are not situated in a nation embargoed by the United States government or designated as a "terrorist supporting" country by the United States government, and (ii) you are not listed on any United States government roster of prohibited or restricted parties.</p>
      <h3 className='text-2xl my-4'>Severability and Waiver</h3>
      <h3 className='text-xl my-4'>Severability</h3>
      <p className='my-1'>If any part of these Terms is determined to be unenforceable or invalid, that provision will be modified and interpreted to achieve the provision's objective to the fullest extent permitted by applicable law, while the remaining portions will continue in full force and effect.</p>
      <h3 className='text-xl my-4'>Waiver</h3>
      <p className='my-1'>Except as expressly specified in these Terms, a party's failure to enforce a right or insist on the performance of an obligation under these Terms will not prevent that party from exercising that right or demanding performance at a later time. Additionally, waiving a violation does not imply a waiver of future breaches.</p>
      <h3 className='text-xl my-4'>Translation Interpretation</h3>
      <p className='my-1'>In the event of a disagreement, you agree that the original English content of these Terms and Conditions shall take precedence, even if it has been translated and made available to you on our Service.</p>
      <h3 className='text-xl my-4'>Changes to These Terms and Conditions</h3>
      <p className='my-1'>We retain the right to modify or replace these Terms at any time at our sole discretion. In the case of a significant change, we will make reasonable efforts to give you at least 30 days' notice before the new terms become effective. The determination of what constitutes a major change shall be entirely at our discretion.
<br />
<br />
By continuing to use or access our Service after the revised terms come into effect, you agree to be bound by the amended terms. If you do not agree to the revised terms, in whole or in part, please discontinue the use of the website and the Service.</p>
      <h3 className='text-2xl my-4'>Contact Us</h3>
      <p className='my-1'>If you have any inquiries about this Disclaimer, please reach us at:</p>
        <li>By email: info@innovapp.co</li>
        <li>By visiting this page on our website: innovapp.co</li>

        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default TermsOfService
