import React from 'react'
import Navigation from '../component/Navigation'
import { IoMdArrowDown } from "react-icons/io";
import th from "../images/th.svg"
import mic from "../images/mic.svg"
import img5 from "../images/5.png"
import img9 from "../images/9.png"
import img6 from "../images/6 (A).png"
import img7 from "../images/7.png"
import img8 from "../images/8.png"
import img12 from "../images/12.png"
import img10 from "../images/10 (B).png"
import t2 from "../images/t2.jpg"
import t3 from "../images/t3.jpg"
import t4 from "../images/t4.jpg"
import Footer from '../component/Footer';
import { Link } from 'react-router-dom';


const Home = () => {
  return (
    <div>
      <Navigation/>
      <div className='grid grid-cols-1 gap-5 max-w-[1280px] px-4 m-auto h-[40rem] items-center pt-20 sm:grid-cols-1 md:grid-cols-2'>
        <div className=' relative'>
          <h1 className='text-5xl md:text-8xl'>Innovate Your <span className='text-blue-500'>Ideas into Mobile</span> Excellence.</h1>
          <h3 className='text-xl my-2'>Transforming Concepts into Cutting-Edge Mobile Applications</h3>
          <p className='text-sm font-light'>Every fine recipe has this one secret ingredient that makes the dish exceptional. We specialize in crafting seamless mobile experiences that captivate and engage users. Let our expert team transform your vision into a top-notch mobile app with stunning design and flawless functionality.</p>
          <div className='flex gap-5 mt-4'>
          <Link to={"/support"} className='bg-black text-white px-5 py-3 rounded-md uppercase'>Start Your Project!</Link>
          <a href='#about' className='flex items-center gap-2 border-2 border-black px-5 py-3 rounded-md uppercase'>Learn More <IoMdArrowDown /> </a>
          </div>
        </div>
        <div className=' relative hidden md:block'>
            <div className='bg-blue-500 w-full h-[40rem] rounded-xl'></div>
                <img className=' w-20 h-20 absolute top-5 left-10' src={mic} alt="img" />
          <img className=' w-[15rem] absolute -bottom-14 left-10 shadow-md rounded-2xl' src={img5} alt="img" />
          <img className=' w-[17rem] absolute -bottom-10 right-10 shadow-md rounded-2xl' src={img9} alt="img" />
        </div>
      </div>


      <div className=' max-w-[1280px] px-4 m-auto mt-48'>
        <div className='bg-white grid grid-cols-1 md:grid-cols-2 gap-5 items-center rounded-2xl' id='about'>
        <div className='p-10'>
            <img src={th} alt="" className='w-[80%]' />
        </div>
        <div className='md:px-5 p-6 py-2 md:py-20 md:pl-0'>
            <h1 className='text-5xl mb-2'>About Us</h1>
            <p className='text-sm'>Building challenging applications let us go outside our comfort zone and rethink our approach and processes for software design, development and delivery.

Innovation was always our key objective from the moment we started. Thanks to this, through the years, we have helped our clients in building and upgrading their products.
<br />
Thanks to our long-lasting knowledge and experience sharing in product development areas, we built a culture that allows us to learn faster than single-product companies. As a result, we can build software products more efficiently.</p>
            <h3 className='text-xl mt-3'>Our Journey</h3>
            <p className='text-sm'>At the heart of our agency lies a commitment to transforming innovative ideas into mobile excellence. We believe that every concept, no matter how big or small, has the potential to become a cutting-edge mobile application that not only meets but exceeds user expectations.</p>
            <p className='text-sm'>Our journey began with a small team of passionate developers and designers who shared a common goal: to create seamless mobile experiences. Our initial projects were modest, but they were driven by a relentless pursuit of quality and innovation.</p>
            <ul className=' list-disc mt-5 text-xs text-gray-500'>
                <li>Vision: Commitment to transforming ideas into mobile excellence.</li>
                <li>Beginnings: Started with a small, passionate team focused on quality and innovation.</li>
                <li>Growth: Expanded services and embraced new technologies.</li>
                <li>Commitment: Maintained unwavering dedication to quality, innovation, and customer satisfaction.</li>
            </ul>
        </div>
        </div>
      </div>

      <div className='max-w-[1280px] px-4 m-auto mt-20'>
        <div className='text-center'>
            <h1 className='text-5xl'>Our Product</h1>
            <p className='text-blue-500'>Discover Our Instant Translator App</p>
            <p className='text-sm font-light mt-2 w-[60%] m-auto'>Our app offers real-time translation, voice recognition, and offline capabilities to ensure you are always understood, no matter where you are.</p>

            <div className='grid grid-cols-1 gap-5 mt-5 sm:grid-cols-1 md:grid-cols-5'>
                    <img src={img6} alt="" className='border border-blue-500 drop-shadow-md rounded-2xl w-full' />
                    <img src={img7} alt="" className='border border-blue-500 drop-shadow-md rounded-2xl w-full' />
                    <img src={img8} alt="" className='border border-blue-500 drop-shadow-md rounded-2xl w-full' />
                    <img src={img10} alt="" className='border border-blue-500 drop-shadow-md rounded-2xl w-full' />
                    <img src={img12} alt="" className='border border-blue-500 drop-shadow-md rounded-2xl w-full' />
            </div>
        </div>
      </div>


      <div className='max-w-[1280px] px-4 m-auto mt-20'>
        <div className='text-center bg-blue-500 text-white py-10 rounded-xl'>
            <h1 className='text-5xl'>Mission and Values</h1>
            <p className='text-sm font-light mt-2 w-[60%] m-auto'>We have the power to make our company better
<br />
We care about what we do. When something needs to be done we step in and take action without waiting for someone else to initiate the change. It is about taking the initiative to create positive results.
<br />
As individuals, we are accountable for the quality and timeliness of an outcome, even when we’re working with others. Each of us should be responsible for the task, team, project, and company. Taking ownership shows others that they can trust us to do the right thing.

</p>
        </div>
      </div>




      <div className='max-w-[1280px] px-4 m-auto mt-20'>
        <div className='text-center'>
            <h1 className='text-5xl'>What We Do</h1>
            <p className='text-sm font-light mt-2 w-[60%] m-auto'>We create bespoke mobile applications tailored to meet your specific needs. Our team excels in developing apps that are not only functional but also provide an exceptional user experience.</p>

            <div className='grid grid-cols-1 gap-5 mt-5 md:grid-cols-3'>
                
                    <div className='text-left bg-white rounded-lg p-5 hover:bg-blue-500 group'>
                        <h1 className='text-2xl group-hover:text-white'>MOBILE APP DEVELOPMENT</h1>
                        <p className='text-sm font-light mt-2 group-hover:text-white'>INNOVAPP’s team consists of experienced mobile app developers working on different platforms (iOS, Android) to build Native platform-specific mobile apps for high performance, high availability and better user experience.</p>
                    </div>
                    <div className='text-left bg-white rounded-lg p-5 hover:bg-blue-500 group'>
                        <h1 className='text-2xl group-hover:text-white'>APP DESIGN</h1>
                        <p className='text-sm font-light mt-2 group-hover:text-white'>Using the latest platform specific guidelines and trends, we develop professional apps that are not only easy on the eyes but also easy to use. When designing our products, we always think about the latest principles that run the digital world.</p>
                    </div>
                    <div className='text-left bg-white rounded-lg p-5 hover:bg-blue-500 group'>
                        <h1 className='text-2xl group-hover:text-white'>STRATEGY AND DESCOVERY</h1>
                        <p className='text-sm font-light mt-2 group-hover:text-white'>Taking a unique point of view about the future to identify opportunities and solving customer and stakeholder problems becomes a must for businesses in order to thrive and be successful.</p>
                    </div>
                    <div className='text-left bg-white rounded-lg p-5 hover:bg-blue-500 group'>
                        <h1 className='text-2xl group-hover:text-white'>Cross-Platform Solutions</h1>
                        <p className='text-sm font-light mt-2 group-hover:text-white'>We specialize in building cross-platform mobile applications that provide a consistent experience across both iOS and Android devices. This approach ensures wider reach and better ROI for your projects.</p>
                    </div>
                    <div className='text-left bg-white rounded-lg p-5 hover:bg-blue-500 group'>
                        <h1 className='text-2xl group-hover:text-white'>App Testing and Quality Assurance</h1>
                        <p className='text-sm font-light mt-2 group-hover:text-white'>Quality is paramount in everything we do. Our rigorous testing and quality assurance processes ensure that your app is reliable, performant, and bug-free before it reaches your users.</p>
                    </div>
                    <div className='text-left bg-white rounded-lg p-5 hover:bg-blue-500 group'>
                        <h1 className='text-2xl group-hover:text-white'>Post-Launch Support and Maintenance</h1>
                        <p className='text-sm font-light mt-2 group-hover:text-white'>Our commitment to your success doesn’t end at launch. We provide ongoing support and maintenance to ensure your app continues to perform optimally and stays up-to-date with the latest technologies and user expectations.</p>
                    </div>
                    
            </div>
        </div>
      </div>


      <div className='max-w-[1280px] px-4 m-auto mt-20'>
        <div className='text-center bg-white rounded-xl p-10'>
            <h1 className='text-5xl'>Meet Our Team</h1>
            <p className='text-sm font-light mt-2 w-[60%] m-auto'>Our team is a diverse group of language enthusiasts, tech experts, and customer support professionals.</p>

            <div className='grid grid-cols-1 gap-5 mt-5 md:grid-cols-3'>
                
                    
                    <div className='text-left bg-white rounded-lg p-5'>
                       <img className='w-full h-[25rem] object-cover object-top rounded-xl' src={t2} alt="team" />
                       <h2 className='text-xl mt-2'>Yassine</h2>
                       <p className='text-sm text-gray-400'>UI/UX Designer</p>
                    </div>
                    <div className='text-left bg-white rounded-lg p-5'>
                       <img className='w-full h-[25rem] object-cover object-top rounded-xl' src={t3} alt="team" />
                       <h2 className='text-xl mt-2'>Chaouki </h2>
                       <p className='text-sm text-gray-400'>IOS Developer</p>
                    </div>
                    <div className='text-left bg-white rounded-lg p-5'>
                       <img className='w-full h-[25rem] object-cover object-top rounded-xl' src={t4} alt="team" />
                       <h2 className='text-xl mt-2'>Mohammed</h2>
                       <p className='text-sm text-gray-400'>Founder</p>
                    </div>
                    
            </div>
        </div>
      </div>

    <Footer/>
    </div>
  )
}

export default Home
