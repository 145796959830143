import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { FiMenu } from "react-icons/fi";

const Navigation = () => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  return (
    <div className='top-2 fixed w-full z-20'>
      <div className='max-w-[1280px] m-auto flex items-center justify-between p-4 bg-gray-100/80 border border-blue-200 rounded-full backdrop-blur-lg'>
        <h1 className='text-lg text-blue-500'>
          <Link to={"/"}><span className='font-bold text-xl'>INNOVAPP</span> <span className='text-sm text-black'>LLC</span></Link>
        </h1>
        <ul className={`${
          menuIsOpen ? "flex absolute bg-white top-16 w-full left-0 p-5" : "hidden"
        } md:flex flex-col md:flex-row items-center gap-6 text-sm`}>
          <li><Link to={"/"}>Home</Link></li>
          <li><Link to={"/statement"}>Statement</Link></li>
          <li><Link to={"/terms-of-service"}>Terms of Service</Link></li>
          <li><Link to={"/privacy-policy"}>Privacy Policy</Link></li>
          <li><Link to={"/support"}>Support</Link></li>
        </ul>
        <button onClick={() => setMenuIsOpen(prev => !prev)} className='md:hidden'>
          <FiMenu className='text-2xl' />
        </button>
      </div>
    </div>
  );
};

export default Navigation;
