import React from 'react'
import Navigation from '../component/Navigation'
import Footer from '../component/Footer'

const Statement = () => {
  return (
    <div>
        <Navigation/>
      
      <div className=' max-w-[1280px] m-auto p-4 mt-20'>
        <div className='bg-blue-500 py-16 text-center rounded-lg'>
        <h1 className='text-5xl text-white uppercase'>Statement</h1>
        </div>

        <div className='mt-10'>
        <h2 className='text-3xl'>Interpretation and Definitions</h2>
<h3 className='text-xl my-4'>Interpretation</h3>
<p className='my-1'>Under the given circumstances, the capitalized nouns have established meanings. Regardless of the form—plural or singular—these definitions are equally applicable.</p>
<h3 className='text-xl my-4'>Definitions</h3>
<p className='my-1'>For the purposes of this Disclaimer:</p>
<ul className='list-disc'>
    <li>Company (referred to as either "the Company", "We", "Us" or "Our" in this Disclaimer) refers to INNOVAPP LLC.</li>
    <li>Service refers to the Application.</li>
    <li>You means the individual accessing the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
    <li>Application means the software program provided by the Company downloaded by You on any electronic device named Healthy Life.</li>
</ul>

<h3 className='text-xl my-4'>Disclaimer</h3>
<p className='my-1'>
    Only broad objectives are served by the information on the Service. <br />
    Any mistakes or omissions in the Service's material are not the Company's fault. <br />
    When using the Service, users agree that the Company will not be responsible for any special, direct, indirect, consequential, or accidental damages. We retain the right to change the content of the Service at any time without warning. <br />
    The Service may include viruses or other dangerous components, which the Company can not guarantee.
</p>

<h3 className='text-xl my-4'>Medical Information Disclaimer</h3>
<p className='my-1'>
    The health information provided on the Service is intended solely for informational purposes and should not be construed as a means to diagnose, treat, cure, or prevent diseases. The inclusion of products, services, and information, including links to third-party websites, is for informational purposes only. <br />
    It's important to note that the information on the Service may not be exhaustive and might not encompass all possible conditions or treatments. Individual reactions to products may vary, and any comments made by employees or users reflect personal opinions and do not constitute claims by the Company. <br />
    The Company cannot be held responsible for the accuracy or completeness of information regarding supplement recommendations on the Service or for any damages arising from the use of the provided information, products, or services. There is no guarantee or warranty provided for sold products or services, and the Company disclaims any responsibility for damages, even if the possibility of such damages has been advised.
</p>

<h3 className='text-xl my-4'>Fitness & Nutritional Information Disclaimer</h3>
<p className='my-1'>
    The Service offers health, fitness, and nutritional information solely for general educational and informational purposes. Your decision to utilize this advice and information is entirely at your own discretion and risk. It should not be considered a replacement for professional medical advice, diagnosis, or treatment. Prior to initiating any nutrition or exercise program, it is crucial to consult with your physician or healthcare provider. <br />
    If you choose to use this information without obtaining consent from your physician, you assume full responsibility for your decisions and hereby agree to absolve the Company of any liability.
</p>

<h3 className='text-xl my-4'>External Links Disclaimer</h3>
<p className='my-1'>
    The Service may contain links to external websites that are not provided or affiliated with the Company. <br />
    The Company does not assure the accuracy, relevance, timeliness, or completeness of the information found on these external sites.
</p>

<h3 className='text-xl my-4'>Advertising Disclaimer</h3>
<p className='my-1'>
    While using the Service, you may encounter third-party ads and links to external websites. The Company cannot guarantee the accuracy or appropriateness of the information contained in those ads or on those websites and assumes no responsibility for their content. <br />
    The presence of ads and links to third-party sites does not imply endorsement or recommendation by the Company. The Company is not accountable for the content, commitments, or quality of products or services advertised on these external sites.
</p>

<h3 className='text-xl my-4'>Errors and Omissions Disclaimer</h3>
<p className='my-1'>
    The Service offers general guidance on various subjects. Despite the Company's efforts to provide up-to-date and accurate content, errors may still occur. Due to the dynamic nature of laws and regulations, there could be delays, omissions, or inaccuracies in the information. <br />
    The Company holds no responsibility for any errors, omissions, or outcomes that may arise from the use of this information.
</p>

<h3 className='text-xl my-4'>Fair Use Disclaimer</h3>
<p className='my-1'>
    The Company may utilize copyrighted material for purposes such as criticism, comment, news reporting, teaching, scholarship, or research. This application is deemed as "fair use" under section 107 of the United States Copyright law. However, if you plan to use copyrighted material from the Service beyond the scope of fair use, it is essential to seek permission from the copyright owner.
</p>

<h3 className='text-xl my-4'>Views Expressed Disclaimer</h3>
<p className='my-1'>
    The views and opinions expressed on the Service are those of the authors and may not necessarily reflect the official policy or position of any other author, agency, organization, employer, or company, including the Company. <br />
    Users bear sole responsibility for the comments they post and will be held accountable for any legal consequences resulting from their comments. The Company is not liable for user comments and reserves the right to delete any comment for any reason.
</p>

<h3 className='text-xl my-4'>No Responsibility Disclaimer</h3>
<p className='my-1'>
    The information provided on the Service is not intended to replace professional advice and services in areas such as legal, accounting, or tax matters. It is offered with the understanding that the Company is not providing professional advice. <br />
    It is strongly advised to seek consultation with qualified advisers in these fields. The Company and its suppliers are not responsible for any special, incidental, indirect, or consequential damages that may arise from your access or use of the Service.
</p>

<h3 className='text-xl my-4'>"Use at Your Own Risk" Disclaimer</h3>
<p className='my-1'>
    All information within the Service is presented on an "as is" basis, without any assurance of completeness, accuracy, timeliness, or results obtained. There is no warranty, whether express or implied, including but not limited to performance, merchantability, and fitness for a particular purpose. <br />
    The Company shall not be held liable for any decisions made or actions taken in reliance on the information provided by the Service and disclaims responsibility for any consequential, special, or similar damages, even if forewarned about the possibility of such damages.
</p>

<h3 className='text-xl my-4'>Contact Us</h3>
<p className='my-1'>
    If you have any inquiries about this Disclaimer, please reach us at:
</p>
<li>By email: info@innovapp.co</li>
        <li>By visiting this page on our website: innovapp.co</li>

        </div>
      </div>
    <Footer/>
    </div>
  )
}

export default Statement
